import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We use postgresql for our database at work. But I was still using the sqlite database for my dev environment. Usually, using a different databse was fine since I didn't have to implement many changes in the queries we had. `}</p>
    <p>{`Although, that changed last week, and I had to install postgresql to run our tests. That was because the tests were failing on our CI, but locally they were passing. It all came to the slight differences between sqlite and postgresql.`}</p>
    <p>{`After installing postgresql, creating a user and a database, I tried to run the tests but got an exception, and all the tests failed with a Permissions Error. This error was a bit surprising since I had created the database, and my user had all the permissions. Looking at the traceback, I noticed the following exception:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`django.db.utils.ProgrammingError: permission denied to create database
`}</code></pre>
    <p>{`We are using `}<inlineCode parentName="p">{`pytest-django`}</inlineCode>{` and the `}<inlineCode parentName="p">{`django_db_setup`}</inlineCode>{` fixture to create a test database, run the tests and check the output. So the issue was that my user had all the permissions for the database that I created, but not for creating new databases. With that in mind, it seemed obvious what I needed to do, give my user permissions to create databases!`}</p>
    <h2 {...{
      "id": "giving-permissions-to-the-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#giving-permissions-to-the-user",
        "aria-label": "giving permissions to the user permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Giving permissions to the user`}</h2>
    <p>{`When you install postgresql (on linux at least), a new user `}<inlineCode parentName="p">{`postgresql`}</inlineCode>{` will be added, you can then use this user to start `}<inlineCode parentName="p">{`psql`}</inlineCode>{` and then create databases and users.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`sudo su - postgresql
psql
`}</code></pre>
    <p>{`Inside `}<inlineCode parentName="p">{`psql`}</inlineCode>{` we can now give permissions to the user I created to create databases.`}</p>
    <pre><code parentName="pre" {...{}}>{`ALTER USER fabiorosado CREATEDB;
`}</code></pre>
    <p>{`Now that the user has permission to create databases, we can go back to try and run the tests. A new testing database gets created, and the tests run successfully (they were also green, which is great!).`}</p>
    <p>{`I hope this helps you if you ever encounter this permissions error.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      